import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { logout } from "../../handlers/logout";
import { myUrl } from "../login/loginSlice";

export interface Ivactions {
  vacations: Array<{
    vacation_Id: number;
    destination: string;
    description: string;
    start: string;
    end: string;
    price: number;
    image: string;
    isFollow?: number;
    followers: number
  }>;

  status: "idle" | "loading" | "succeeded" | "failed";
}
interface Ivaction {
  vacation_Id?: number;
  destination: string;
  description: string;
  start: string;
  end: string;
  price: number;
  image: string;
}

const initialState: Ivactions = {
  vacations: [
    {
      vacation_Id: 0,
      destination: "",
      description: "",
      start: "",
      end: "",
      price: 0,
      image: "",
      isFollow: 0,
      followers: 0
    },
  ],

  // followedVacations: [],

  status: "idle",
};




export const addVacation = createAsyncThunk(
  "vacations/addVacation",
  async (vacationData: Ivaction, { rejectWithValue }) => {
    if (!vacationData ) return
    try {
      const response = await axios.post(
        `${myUrl}/vacations/newv`,
        vacationData,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      rejectWithValue("Failed to add vacation")
      throw new Error("Failed to add vacation")

    }
  }
);
export const followVacation = createAsyncThunk(
  "vacations/followVacation",
  async ({ vacationId, userId }: any, { rejectWithValue }) => {

    try {
      const response = await axios.post(
        `${myUrl}/followers/follow?id=${userId}`,
        { vacationId },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error.message === "Request failed with status code 401") {
        logout()
      }
      return rejectWithValue("Failed to follow vacation");
    }
  }
);
export const removeFollow = createAsyncThunk(
  "vacations/removeFollow",
  async ({ vacationId, userId }: any, { rejectWithValue }) => {

    try {
      const response = await axios.delete(
        `${myUrl}/followers/remove/${vacationId}/${userId}`,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (error: any) {
      if (error.message === "Request failed with status code 401") {
        logout()

      }
      return rejectWithValue("Failed to remove follow ");
    }

  }


);
export const fetchFollowedVacations = createAsyncThunk("vacations/fetchFollowedVacations", async (userId: number) => {

  try {
    const response = await axios.get(`http://localhost:4101/vacations/followedVacations?q=${userId}`, {
      headers: {
        authorization: localStorage.getItem("token")
      }
    });

    return response.data;
  } catch (error: any) {
    if (error.message === "Request failed with status code 401") {
      logout()

    }
  }
});
export const fetchVacationByName = createAsyncThunk("vacations/fetchVacationByName", async ({ name, userId }: any) => {

  try {
    const response = await axios.get(`http://localhost:4101/vacations/search?q=${name}&id=${userId}`, {
      headers: {
        authorization: localStorage.getItem("token")
      }
    });
    return response.data;
  } catch (error: any) {
    if (error.message === "Request failed with status code 401") {
      logout()

    }
  }
});



export const vacationsSlice = createSlice({
  name: "vacations",
  initialState,
  reducers: {


  },
  extraReducers: (builder) => {
    builder

      .addCase(addVacation.fulfilled, (state, action) => {
        state.status = "succeeded";

      })
      .addCase(fetchVacationByName.fulfilled, (state, action) => {

        state.status = "succeeded";

      }).
      addCase(followVacation.rejected, (state, action) => {
        state.status = "failed";

      })
      .addCase(fetchFollowedVacations.fulfilled, (state, action) => {
        state.vacations = action.payload
      });
  },
});

export default vacationsSlice.reducer;
