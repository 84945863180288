import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './cv.css'; 
import me from "../../../upload/me.jpeg"

const CV = () => {
  const containerRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ opacity: 1 });
  }, [controls]);

  return (
    <div className="cvContainer" ref={containerRef}>
      <motion.div className="cvSection" initial={{ opacity: 0 }} animate={controls} transition={{ duration: 1 }}>
        <img src={me} alt="Snir Evgi" className="cvImage" />
        <h1 className="cvName">Snir Evgi</h1>
        <p className="cvText">ID: 211649397</p>
        <p className="cvText">Email: Snirevg@gmail.com</p>
        <p className="cvText">City: Gan Yavne</p>
        <p className="cvText">Phone: 050-6798-676</p>
        <p className="cvText">Car-Mobility: Yes</p>
      </motion.div>

      <motion.div className="cvSection" initial={{ opacity: 0 }} animate={controls} transition={{ duration: 1 }}>
        <h2 className="cvSubtitle">Work History</h2>
        <p className="cvText">Feb 2022 - June 2023</p>
        <p className="cvText">Company: Depoint</p>
        <p className="cvText">Location: Ashdod, Israel</p>
        <p className="cvText">Position: Manual QA Tester</p>
        <br />
        <h4 className="cvText">Responsibilities:</h4>
        <ul className="cvList">
          <li>Implementing content in the system for customers and customers success team</li>
          <li>Interacting daily with customers under the support platform for system guidance and finding system issues</li>
          <li>Troubleshooting system issues</li>
          <li>Reporting bugs in Monday for the dev team and submitting features ideas for further development</li>
          <li>Building testing checklists in Monday</li>
        </ul>
      </motion.div>

      <motion.div className="cvSection" initial={{ opacity: 0 }} animate={controls} transition={{ duration: 1 }}>
        <h2 className="cvSubtitle">Education History</h2>
        <p className="cvText">2006 - 2018: 12 years of education with 5 units in English and school diploma</p>
        <p className="cvText">2023 - Now: Participated in a Fullstack Web course at John Bryce TLV, Israel</p>
      </motion.div>

      <motion.div className="cvSection" initial={{ opacity: 0 }} animate={controls} transition={{ duration: 1 }}>
        <h2 className="cvSubtitle">Military Service</h2>
        
        <p className="cvText"> 2018 - 2021: Served full service as a truck driver, in my service I have served as a truck driver in Eilat and acted as head of the drivers team, in my daily job I assigned drivers and trained new drivers in their adjustment period.  </p>
      </motion.div>

      <motion.div className="cvSection" initial={{ opacity: 0 }} animate={controls} transition={{ duration: 1 }}>
        <h2 className="cvSubtitle">Skills</h2>
        <div className="cvSkills">
          <span className="cvSkill">HTML</span>
          <span className="cvSkill">CSS</span>
          <span className="cvSkill">JavaScript</span>
          <span className="cvSkill">TypeScript</span>
          <span className="cvSkill">React</span>
          <span className="cvSkill">Angular</span>
          <span className="cvSkill">SQL</span>
          <span className="cvSkill">Node.js</span>
          <span className="cvSkill">Docker</span>
          <span className="cvSkill">Postman</span>
          <span className="cvSkill">jQuery</span>
          <span className="cvSkill">Git</span>
          <span className="cvSkill">Webpack</span>
          <span className="cvSkill">Express.js</span>
          <span className="cvSkill">RESTful API</span>
          <span className="cvSkill">Responsive Design</span>
          <span className="cvSkill">Monday</span>
        </div>
      </motion.div>
    </div>
  );
};

export default CV;
