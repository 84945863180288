import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchFollowedVacations, followVacation, removeFollow, fetchVacationByName } from './vacationSlice';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Paginator } from 'primereact/paginator';
import "./Vacations.css"
import Header from '../../UI-Components/header';
import alt from "../../../upload/alt.jpg"
import SearchDestination from '../../UI-Components/search';
import { userData } from '../../handlers/hashData';
import { motion as m } from "framer-motion"
import CircularColor from '../../UI-Components/Spinner/Spinner';
import { AnyAction } from '@reduxjs/toolkit';
import { SyntheticEventData } from 'react-dom/test-utils';
import { Dropdown } from 'primereact/dropdown';
import { Slider } from 'primereact/slider';

const VacationComponent = () => {
  const { id, first_name, role } = userData
  const [vacations, setVacations] = useState<Array<any>>([])
  const [showFollowed, setShowFollowed] = useState<boolean>(false);
  const [showNotStarted, setShowNotStarted] = useState<boolean>(false);
  const [sortByPrice, setSortByPrice] = useState<string>('asc');
  const [selectedPriceRange, setSelectedPriceRange] = useState<any>([0, 10000]);
  const [zoomedIndex, setZoomedIndex] = useState(-1);
  const [first, setFirst] = useState(0)
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const itemPerPage = 9
  const dispatch = useAppDispatch();
  const followedVacations = useAppSelector((state) => state.vacations.vacations);
  const userId = Number(id);
  const handleChange = (e: any) => {
    setFirst(e.first);

  }

  const handleAllSearch = async () => {
    const result = await dispatch(fetchFollowedVacations(userId));
    setVacations(result.payload)
    setUserName(first_name)
    setSearchKeyword("");
  };
  useEffect(() => {
    document.title = `Fly-High`
    try {
      handleAllSearch()
    } catch (error) {
      console.log(" we have an error");
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);


  const handleFollow = async (vacationId: number, isFollowing: any) => {
    try {
      if (isFollowing === 0) {
        await dispatch(followVacation({ vacationId, userId }));
        setVacations((prevVacations) =>
          prevVacations.map((vacation) =>
            vacation.vacation_Id === vacationId
              ? { ...vacation, isFollow: 1, followers: vacation.followers + 1 }
              : vacation
          )
        );
      } else {
        await dispatch(removeFollow({ vacationId, userId }));
        setVacations((prevVacations) =>
          prevVacations.map((vacation) =>
            vacation.vacation_Id === vacationId
              ? { ...vacation, isFollow: 0, followers: vacation.followers - 1 }
              : vacation
          )
        );
      }

    } catch (error: any) {
      console.log(error, "This is an error.");
    }
  };


  const handleSearch = async (name: string) => {
    const result = await dispatch(fetchVacationByName({ name, userId }));
    setVacations(result.payload)
    await dispatch(fetchFollowedVacations(userId))
    setSearchKeyword(name);
  };



  const hasNotStarted = (start: string) => {
    const startDate = new Date(start);
    const currentDate = new Date();
    startDate.setUTCHours(0, 0, 0, 0);
    currentDate.setUTCHours(0, 0, 0, 0);
    return startDate > currentDate;
  };
  const handlePriceRangeChange = (e: any) => {
    setSelectedPriceRange(e.value);
  };

  const handleSortChange = (e: any) => {
    setSortByPrice(e.value);
  };



  const visibleVacations = vacations
    .filter((vacation) => (!showFollowed || vacation.isFollow === 1))
    .filter((vacation) => (!showNotStarted || hasNotStarted(vacation.start)))
    .filter((vacation) =>
      searchKeyword ? vacation.destination.toLowerCase().includes(searchKeyword.toLowerCase()) : true
    )
    .filter((vacation) => vacation.price >= selectedPriceRange[0] && vacation.price <= selectedPriceRange[1])
    .slice()
    .sort((a, b) => {
      if (sortByPrice === 'asc') {
        return a.price - b.price;
      } else {
        return b.price - a.price;
      }
    })
    .slice(first, first + itemPerPage);

  return (
    <m.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.55, ease: "easeOut" }}
      className="vacationsContainer">
      <div className="userWelcome">
        {role === "admin" ?
          (<span>Welcome, <span className="userName">System Admin</span>!</span>) :
          (<span>Welcome, <span className="userName">{userName}</span>!</span>)
        }
      </div>
      <Header title="Vacations" />
      <div className="filterOptions">
        
          <div className="formGroup userSearch">
            <SearchDestination searchAction={handleSearch} allAction={handleAllSearch} />
          </div>
          <div className="formGroup pFieldCheckbox">
            <div className="checkboxLabel">
              <Checkbox
                className="checkboxInput"
                inputId="showFollowed"
                checked={showFollowed}
                onChange={() => setShowFollowed(!showFollowed)}
              />
              <label htmlFor="showFollowed">Show Only Followed Vacations</label>
            </div>
          </div>
          <div className="formGroup pFieldCheckbox">
            <div className="checkboxLabel">
              <Checkbox
                className="checkboxInput"
                inputId="showNotStarted"
                checked={showNotStarted}
                onChange={() => setShowNotStarted(!showNotStarted)}
              />
              <label htmlFor="showNotStarted">Show Only Vacations Not Started Yet</label>
            </div>
            <div className="secondP">
              <div className="sliderLabel priceRangeSlider">
                <label htmlFor="priceRange">Price Range: ${selectedPriceRange[0]} - ${selectedPriceRange[1]}</label>
                <Slider
                  id="priceRange"
                  range
                  min={0}
                  max={10000}
                  value={selectedPriceRange}
                  onChange={handlePriceRangeChange}
                  step={100}
                />
              </div>
              <div className="dropdownLabel sortingDropdown">
                <Dropdown
                  value={sortByPrice}
                  options={[
                    { label: 'Low to High', value: 'asc' },
                    { label: 'High to Low', value: 'desc' },
                  ]}
                  onChange={handleSortChange}
                  placeholder="Sort by Price"
                />
              </div>
            </div>
          </div>
      </div>
      <Paginator
        first={first}
        rows={itemPerPage}
        totalRecords={vacations.length}
        onPageChange={handleChange}
      />
      {isLoading ? (
        <CircularColor />
      ) : (
        <div>
          {visibleVacations.map((vacation, index) => (
            <div key={index}>
              <Card title={vacation.destination} className="cardContainer" key={vacation.vacation_Id}>
                <button onClick={() => {
                  handleFollow(vacation.vacation_Id, vacation.isFollow)
                }
                }>
                  {vacation.isFollow === 1 ? "Unfollow" : "Follow"}
                </button>
                <div className="followerCounter">
                  <i className="pi pi-user" /> {vacation.followers} Followers
                </div>
                <img
                  src={vacation.image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = alt;
                  }}
                  className={`cimages ${zoomedIndex === index ? 'zoomed' : ''}`}
                  onClick={() => setZoomedIndex(index === zoomedIndex ? -1 : index)}
                  alt={vacation.destination}
                />
                <div className="cardDetails">
                  <p>{vacation.description}</p>
                </div>
                <p className='price'> {vacation.price} $     </p><br />


                <div className="dates">
                  <p className="dateText">
                    {vacation.start.split(' ')[0].split('-').reverse().join('/')} -{' '}
                    {vacation.end.split(' ')[0].split('-').reverse().join('/')}
                  </p>
                </div>
              </Card>
            </div>
          ))}
        </div>
      )}
    </m.div>
  );

};

export default VacationComponent;
