import React, { useState ,useRef,useEffect} from 'react';
import "./chatbot.css"
interface Message {
  text: string;
  isUser: boolean;
}

function Chatbot(props:any) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<string>('');
  const chatbotMessagesRef = useRef<HTMLDivElement | null>(null);

  const prebuiltMessages: string[] = [
    "I forgot my password",
    "I'm having trouble signing in",
    "Im Having a 404 Not Found error",
    
  ];
  const scrollToBottom = () => {
    if (chatbotMessagesRef.current) {
      chatbotMessagesRef.current.scrollTop = chatbotMessagesRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom(); 
  }, [messages]);
  const addMessage = (text: string, isUser = false) => {
    setMessages([...messages, { text, isUser }]);
  };

  const handleSelectMessage = (message: string) => {
    setSelectedMessage(message);
    setIsLoading(true)
    setTimeout(()=>{setIsLoading(false),addMessage(message, true),processUserMessage(message),  scrollToBottom(); },1800)
 
  };

  const processUserMessage = (userMessage: string) => {
    const responses: Record<string, string> = {
      "I forgot my password": "You can reset your password by please contact our support team at support@flyhigh.com",
      "I'm having trouble signing in": "Please make sure you are using the correct username, for more information please contact our support team at support@flyhigh.com.",
      "Im Having a 404 Not Found error": `Make sure you are looking in the right place and not trying to access forbidden areas,for more information please contact our support team at support@flyhigh.com.`,
    };

    const botResponse = responses[userMessage] || "I'm sorry, I don't understand that request.";
    addMessage(botResponse);
  };

  return (
    <div className="chatbot-container">
        <button className='pi pi-times myChatBtn' onClick={props.closeChat}></button>
      <div className="chatbot-messages" ref={chatbotMessagesRef}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.isUser ? 'user' : 'bot'}`}>
            { message.text}
            {<br/>}
          </div>
        ))}
         {isLoading&&(
            <div  className={`message bot`}>
            . . . .
          </div>
            
        )}
      </div>
      <div className="prebuilt-messages">
        <p>Select a message:</p>
        <ul>
          {prebuiltMessages.map((message, index) => (
            <li key={index} onClick={() => handleSelectMessage(message)}>
              {message}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Chatbot;
