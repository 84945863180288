import React from 'react';
import { Avatar } from 'primereact/avatar';
import supportImage from "../../../upload/support.jpg"
import "./chatAvatar.css"
function ChatAvatar(props:any ) {
  return (
    <div className="chatAvatar" onClick={props.onClick}>
      <Avatar
        image={supportImage} 
        shape="circle"
        size="large"
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
}

export default ChatAvatar;
