import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import vacationsSlice from "../features/pages/Vacations/vacationSlice";
import registerSlice from "../features/pages/register/registerSlice";
import { adminVacationsSlice } from "../features/pages/adminVacation/adminVacationSlice";

export const store = configureStore({
  reducer: {
    vacations: vacationsSlice, 
    adminVacation: adminVacationsSlice.reducer, 
    register:  registerSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
