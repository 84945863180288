import  { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import showToast from "../../handlers/toast";
import { useAppDispatch } from "../../../app/hooks";
import { InputNumber } from "primereact/inputnumber";
import "./editForm.css"
import Header from "../../UI-Components/header";
import { format } from "date-fns";
import CircularColor from "../../UI-Components/Spinner/Spinner";

const EditForm = ({ vacation, onEditComplete, updateFunction, onCancel }: any) => {
    const toastRef = useRef<Toast>(null);
    const dispatch = useAppDispatch();
    const [vid, setId] = useState<string>(vacation.vacation_Id);
    const [destination, setDestination] = useState<string>(vacation.destination);
    const [description, setDescription] = useState<string>(vacation.description);
    const [end, setEnd] = useState<string>(vacation.end);
    const [start, setStart] = useState<string>(vacation.start);
    const [date, setDate] = useState<string>(vacation.start);
    const [price, setPrice] = useState<number>(vacation.price);
    const [image, setImage] = useState<string>(vacation.image);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const handleSubmit = async () => {
        setIsLoading(true);


        const updatedVacation = {
            ...vacation,
            destination,
            description,
            start: format(new Date(start), 'yyyy-MM-dd'),
            end: format(new Date(end), 'yyyy-MM-dd'),
            price,
        };


        try {
            await updateFunction(updatedVacation, vid);
            setIsLoading(false);
            showToast(
                toastRef,
                "success",
                "Vacation Updated",
                "Vacation has been successfully updated."
            );
            onEditComplete();
        } catch (error: any) {
            setIsLoading(false);
            showToast(
                toastRef,
                "error",
                "Error",
                "An error occurred while updating the vacation."
            );
            // console.error("Error updating vacation:", error);
        }
    };

    

    return (
        <div className="editVacation">
            <Toast ref={toastRef} />
            <div className="editVacationCard">
                <div className="editHead">
                    <Header title="Edit Vacation" />
                </div>
                <InputText
                    className="inputText"
                    placeholder="Destination"
                    value={destination}
                    onChange={(e) => setDestination(e.target.value)}
                    required
                />
                <textarea
                    className="editVacationTextarea"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                />
                <InputText
                    className="inputText"
                    placeholder="Start Date"
                    value={start}
                    type="date"
                    onChange={(e) => {setStart(e.target.value),setDate(e.target.value)}}
                    required
                />
                <InputText
                    className="inputText"
                    placeholder="End Date"
                    type="date"
                    min={date}
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                    required
                />
                <InputNumber
                    className="inputNumber"
                    placeholder="Price"
                    value={price}
                    onValueChange={(e: any) => setPrice(e.value)}
                    required
                />
                <InputText
                    className="inputText"
                    placeholder="Image"
                    type="text"
                    value={image}
                    onChange={(e: any) => setImage(e.target.value)}
                    required
                />
                <div className="buttonsDiv">
                    <Button
                        className="submitButton"
                        onClick={handleSubmit}
                        label="Update Vacation"
                    />
                    <Button
                        className="cancelButton"
                        onClick={onCancel}
                        label="X"
                    />
                </div>
                {isLoading && <CircularColor />}
            </div>
        </div>
    );
};

export default EditForm;
