import { useState, useEffect } from 'react';
import "./followedList.css"
import { userData } from '../../handlers/hashData';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchFollowedVacations } from '../../pages/Vacations/vacationSlice';
import { Avatar } from 'primereact/avatar';
import Header from '../header';
const { id, email, first_name } = userData


const FollowedList = () => {
  const followedVacations = useAppSelector((state) => state.vacations.vacations);
  const dispatch = useAppDispatch();

  const [items, setItems] = useState();
  const [visible, setVisible] = useState<boolean>(false);
  const prepData = followedVacations.filter((v) => {
    return v.isFollow === 1
  })



  const toggleSidebar = () => {
    setVisible(!visible);
  };
  const fetch = async () => {
    await dispatch(fetchFollowedVacations(id));
  };
  useEffect(() => {
    fetch()
  }, [])

  return (
    <div className="list" >
      <div className="btnList" >
        <button className="toggleFollowedList" onClick={toggleSidebar}>
          <i className={`pi ${visible ? 'pi-angle-down' : 'pi-heart'}`}></i>
        </button>
      </div>
      {visible && (
        <div className="listItems" onMouseLeave={(e:any)=>{setVisible(false)}}>
            <div className='infoD'>
              <h3>Followed Vacations</h3>
              <br />
              <span className="name"> <i className="pi pi-user" /> {first_name}</span>
              <br />
              <span className="email">@ {email}</span>
            </div>
            
            {prepData.map((it: any, index: any) => (
              <li key={index} className="listItem">
                <Avatar image={it.image} className="avatar" size="large" shape="circle" />
                <div className="itemInfo">

                  <span className="destination">{it.destination}</span>

                  <div className='followersDiv'>
                    <span className="followers"><i className="pi pi-user" /> {it.followers}</span>
                  </div>
                </div>
              </li>
            ))}
        </div>
      )}
    </div>
  );
}

export default FollowedList;
