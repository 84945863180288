import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { signUpAsync } from "./registerSlice";
import { useNavigate } from "react-router-dom";
import { object, string } from "zod";
import { AppDispatch } from "../../../app/store";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./registeStyle.css";
import Swal from "sweetalert2";
import ChatAvatar from "../../UI-Components/chatAvatar";
import Chatbot from "../../UI-Components/chatbot";
import showToast from "../../handlers/toast";
import { Toast } from "primereact/toast";

interface RegistrationInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  question: string;
}

const RegistrationComponent = () => {
  const toastRef = useRef<Toast>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSupportOn, setIsSupportOn] = useState<boolean>(false)

  const registrationSchema = object({
    email: string().email("Invalid email"),
    firstName: string().max(12, "First name is to long"),
    password: string().min(6, "Password must be at least 6 characters"),
    lastName: string().max(15, "Last name is to long"),
  });
  useEffect(() => {
    document.title = `Sign Up`
  }, [])
  const methods = useForm<RegistrationInput>({
    resolver: zodResolver(registrationSchema),

  });

  const signUp = async (data: RegistrationInput, dispatch: AppDispatch) => {
    try {
      const result = await dispatch(signUpAsync(data));
      if (signUpAsync.fulfilled.match(result)) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'User registered successfully!',
        }).then(() => {
          setTimeout(() => {
            navigate("/login");
          }, 500);
        });

      } else {
        showToast(toastRef, 'error', 'Error', 'The email or password do not exist.');
      }
    } catch (error) {
      console.log(error, "Error");
    }
  };
  const handleNotIn = () => {
    navigate("/login")
  };
  // const handleIn = () => {
  //   navigate("/")
  // };

  const handleSupportChat = async () => {
    setIsSupportOn(!isSupportOn)
  };

  const signUpService = async () => {
    try {
      const data = {
        email: methods.getValues("email"),
        firstName: methods.getValues("firstName"),
        password: methods.getValues("password"),
        lastName: methods.getValues("lastName"),
        question: methods.getValues("question").toLowerCase(),
      };
      await signUp(data, dispatch);
      methods.reset();
    } catch (ex) {
      console.log(ex);

    }
  };
  return (

    <FormProvider {...methods}>
      <Toast ref={toastRef} />

      <ChatAvatar onClick={handleSupportChat} />
      {isSupportOn && (
        <Chatbot closeChat={() => { setIsSupportOn(false) }} />
      )
      }
      <div className="registerStyle">
        Email
        <InputText className="inputText2" type="email" {...methods.register("email")} />
        <br />
        {methods.formState.errors.email && (
          <span>{methods.formState.errors.email.message}</span>
        )}<br />
        First Name
        <InputText required className="inputText2" type="text" {...methods.register("firstName")} />
      <br />
        {methods.formState.errors.firstName && (
          <span>{methods.formState.errors.firstName.message}</span>
        )}<br />
        Last Name
        <InputText required className="inputText2" type="text" {...methods.register("lastName")} />
      <br />
        {methods.formState.errors.lastName && (
          <span>{methods.formState.errors.lastName.message}</span>
        )}<br />
        Password
      <br />
        <InputText required className="inputText2" type="password" {...methods.register("password")} />
        {methods.formState.errors.password && (
          <span>{methods.formState.errors.password.message}</span>
        )}<br />
        Security Question  -  <span><small><u>Remember the answer for account security reasons</u></small></span>
        <InputText required className="inputText2" type="text" placeholder="What is your first pet name?" {...methods.register("question")} />
        <br />
        {methods.formState.errors.question && (
          <span>{methods.formState.errors.question.message}</span>
        )}
      </div>
      <Button className="registerButton" type="button" onClick={methods.handleSubmit(signUpService)}>
        Sign Up
      </Button>
      <div className="loginQ">
        <span> Already got a user ?</span>
        <button className="LoginBtn" onClick={handleNotIn}> Login! </button>
        <br />
      </div>
    </FormProvider>
  );
};

export default RegistrationComponent;
