import {  createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { logout } from "../../handlers/logout";
import { myUrl } from "../login/loginSlice";

export interface IvacationsAdmin {
  vacationsAdmin: Array<{
    vacation_Id: number;
    destination: string;
    description: string;
    start: string;
    end: string;
    price: number;
    image: string;
    isFollow?:number;
    followers:number
  }>;

  status: "idle" | "loading" | "succeeded" | "failed";
}

const initialState: IvacationsAdmin = {
  vacationsAdmin: [
    {
      vacation_Id: 0,
      destination: "",
      description: "",
      start: "",
      end: "",
      price: 0,
      image: "",
      isFollow:0,
      followers:0
    },
  ],

  status: "idle",
};

export const fetchVacations = createAsyncThunk("vacations/fetchVacations", async (userId:number) => {
  try {
    const response = await axios.get(`${myUrl}/vacations/followedVacations?q=${userId}`, {
      headers: {
        authorization: localStorage.getItem("token"),
      }
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    if (error.message === "Request failed with status code 401") {
      logout()
    }
  }
});

export const deleteVacation = createAsyncThunk(
  "vacations/deleteVacation",
  async (vacationId: number, { rejectWithValue }) => {

    try {
      const response = await axios.delete(
        `${myUrl}/vacations/deleteVacation?q=${vacationId}`,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      
      return response.data;
    } catch (error:any) {
      console.log(error);
      if (error.message === "Request failed with status code 401") {
        logout()
      }
      return rejectWithValue("Failed to follow vacation");
    }
  }
);
export const updateVacation = createAsyncThunk(
  "vacations/updateVacation",
  async ( {editedVacation,id }:any, { rejectWithValue }) => {

    try {
      const response = await axios.put(
        `${myUrl}/vacations/update?id=${id}`,
        {
          vacationData: editedVacation,
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
          
        }
        
      );
      
      return response.data;
    } catch (error:any) {
      if (error.message === "Request failed with status code 401") {
        logout()
      }
      // console.log(error);
      return rejectWithValue("Failed to update vacation");
    }
  }
);

  




export const adminVacationsSlice = createSlice({
  name: "adminVacations",
  initialState,
  reducers: {


  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVacations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.vacationsAdmin = action.payload;
        
      })
      .addCase(fetchVacations.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteVacation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteVacation.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteVacation.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateVacation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateVacation.fulfilled, (state, action) => {
        state.status = "succeeded";
        
      })
      .addCase(updateVacation.rejected, (state) => {
        state.status = "failed";
      })
      
  },
});

export default adminVacationsSlice.reducer;
