import { Navigate } from "react-router-dom"
import NotFound from "../../pages/notFound";
import { userData } from "../../handlers/hashData";
const { id, role } = userData



export function AdminProtectedRoute(props: { children: any }) {
  const token = localStorage.getItem("token");

    
    if (token&&role  === "admin") {
        return props.children; 
    }
    else if (!token || role === "user") {
       return <NotFound/>
    
    } 
     else {
        return <Navigate to="/login" />;
    }
}
function checkUserAdminStatus() {
   
    // const userRole = localStorage.getItem("role");
    // return userRole === "admin";
  }