import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import axios from "axios";
import { myUrl } from "../login/loginSlice";

export interface SignupState {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  question: string;
}

const initialRegisterState: SignupState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  question: "",
};

export const signUpAsync = createAsyncThunk(
  "register/signUp",
  async (payload: SignupState) => {
    try {
      const response = await axios.post(`${myUrl}/auth/sign-up`, payload);
      return response.data; 
    } catch (error) {
      throw new Error("Register Failed")
    }
  }
);

export const registerSlice = createSlice({
  name: "register",
  initialState: initialRegisterState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    clearSignupData: (state) => {
      return initialRegisterState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUpAsync.fulfilled, (state, action) => {
       state.email = action.payload.email;
       state.firstName = action.payload.firstName;
       state.lastName = action.payload.lastName;
       state.password = action.payload.password;
      //  console.log(state);
       
    });
  },
});

export const { setFirstName, setLastName, setEmail, setPassword, clearSignupData } = registerSlice.actions;

export const selectFirstName = (state: RootState) => state.register.firstName;
export const selectLastName = (state: RootState) => state.register.lastName;
export const selectEmail = (state: RootState) => state.register.email;
export const selectPassword = (state: RootState) => state.register.password;

export default registerSlice.reducer;
