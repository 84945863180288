import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate, BrowserRouter as Router, useLocation } from "react-router-dom";
import { AdminProtectedRoute } from "./features/UI-Components/protect-admin";
import { ProtectedRoute } from "./features/UI-Components/protected-route";
import { easeOut, motion as m, useInView, useScroll } from "framer-motion"
import { Avatar } from "primereact/avatar";
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import "./index.css"
import Vacations from "./features/pages/Vacations";
import RegistrationComponent from "./features/pages/register";
import LoginComponent from "./features/pages/login";
import AddVacationAdmin from "./features/pages/addVacation";
import AdminVacationComponent from "./features/pages/adminVacation";
import EditForm from "./features/pages/editForm";
import logoImage from '../src/upload/logo.png';
import NotFound from "./features/pages/notFound";
import HomePage from "./features/pages/homePage";
import SideBar from "./features/UI-Components/sidebar";
import ChatbotPage from "./features/pages/chatbotPage";
import AboutUs from "./features/pages/aboutUs/aboutUs";
import github from "./upload/github.png";
import support from "./upload/24-hours-support.png";
import FollowedList from "./features/UI-Components/followedList";
import { userData } from "./features/handlers/hashData";
import ResetPasswordComponent from "./features/pages/forgotPassword";
import CV from "./features/pages/cv";
import Chatbot from "./features/UI-Components/chatbot";
const token = localStorage.getItem("token");
const { id, role, first_name } = userData
const userId = id
const userRole = role
interface IRoute {
  path: string;
  key: string;
  component: any;
  label?: string;
  onlyAdmin?: boolean;
}
const routes: Array<IRoute> = [
  {
    path: "/vacations",
    component: <ProtectedRoute> <Vacations /></ProtectedRoute>,
    key: "Vacations",

  },
  {
    path: "/chatbotPage",
    component: <ChatbotPage />,
    key: "chatbot",

  },
  {
    path: "/about",
    component: <CV />,
    key: "aboutMe",

  },
  {
    path: "/addVacations",
    component: <AdminProtectedRoute><AddVacationAdmin /></AdminProtectedRoute>,
    key: "addVacations",
  },
  {
    path: "/adminVacation",
    component: <AdminProtectedRoute><AdminVacationComponent /></AdminProtectedRoute>,
    key: "AdminVacations",


  },
  {
    path: "/adminEditVacation",
    component: <AdminProtectedRoute><EditForm /></AdminProtectedRoute>,
    key: "adminEditVacation",

  },
  {
    path: "/sign-up",
    component: <RegistrationComponent />,
    key: "sign-up",

  },
  {
    path: "/home",
    component: <HomePage />,
    key: "home",

  },

  {
    path: "/login",
    component: <LoginComponent />,
    key: "login",

  },
  {
    path: "/reset",
    component: <ResetPasswordComponent />,
    key: "reset",

  },
  {
    path: "/",
    component: <HomePage />,
    key: "home_page",
  },
  {
    path: "*",
    component: <NotFound />,
    key: "not_found",
  }

];

function App() {
  const { scrollYProgress } = useScroll()
 
  return (
    <Router >
      <Navbar />
      <m.div
        className="progressBar"
        style={{ scaleX: scrollYProgress }}
      />
      <div className="pageContent">
        <Routes>
          {routes.map((route) => (
            showRoutesPerRole(route) && (
              <Route
                path={route.path}
                key={route.key}
                element={route.component}
              />
            )
          ))}
        </Routes>
      </div>
    </Router>
  );
}
function Navbar() {
  
  const navigate = useNavigate();
  const location = useLocation();
  const [isSupportOn, setIsSupportOn] = useState<boolean>(false)
  const handleNavigation = async () => {
    const routeByRole = userRole === 'admin' ? '/adminVacation' : '/vacations';
    if (token && location.pathname === '/home') {
      navigate(routeByRole);
    } else if (!token) {
      navigate("/home")
    }
    else { navigate("/home") }
  };
  const handleLogout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("hashedData");
    localStorage.removeItem("exp");
    window.location.href = "/home";
  };
  const handleSupportChat = async () => {
    setIsSupportOn(!isSupportOn)
};
  const routeByRole = userRole === "admin" ? "/adminVacation" : "/vacations"
  return (
    <nav className="navbar">
      <div className="logo">
        <span className="navbarBrand" onClick={handleNavigation}>
          Fly-High
        </span>
        <img className="logoImage" onClick={handleNavigation} src={`${logoImage}`} alt="Airplane" />
      </div>
      <ul className="navLinks">
        {routes.map((route) => (
          showRoutesPerRole(route) &&
          (<li key={route.path}>
            <Link to={route.path} className="navLink">
              {route.label}
            </Link>
          </li>)
        ))}
      </ul>
      <div>
      </div>
      <br />
      <br />
      {isSupportOn && (
                  <Chatbot closeChat={() => { setIsSupportOn(false) }} />
              )
          }
      <div className="topFn">
        <div className="support" onClick={handleSupportChat}>
          <Avatar image={support} className="supportIcon" size="normal" shape="circle" />
        </div>
        <a href="https://github.com/snirEvgi" target="_blank">
          <Avatar image={github} className="githubIcon" size="normal" shape="circle" />
        </a>      {token && (
          <FollowedList />

        )}
        {token && (
          <button className="logoutButton" onClick={handleLogout}>
            Logout
          </button>
        )}
        <br />
        <div className="sideBarDiv">
          <SideBar />
        </div>
      </div>
    </nav>
  );
}
function showRoutesPerRole(route: IRoute) {
  if (route.onlyAdmin) {
    const userRole = localStorage.getItem("role");
    return userRole === "admin";
  }

  return true;
}

export default App;
