import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Card } from 'primereact/card';
import { IvacationsAdmin, deleteVacation, fetchVacations, updateVacation } from './adminVacationSlice';
import "./AdminVacations.css"
import Header from '../../UI-Components/header';
import { Paginator } from 'primereact/paginator';
import EditForm from '../editForm';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Chart } from 'primereact/chart';
import { CSVLink } from "react-csv";
import alt from "../../../upload/alt.jpg"
import { userData } from '../../handlers/hashData';
import CircularColor from '../../UI-Components/Spinner/Spinner';
import { motion as m } from "framer-motion"

const { id } = userData

const AdminVacationComponent = () => {
  const navigate = useNavigate();
  const [showChart, setShowChart] = useState(false);
  const [editedVacation, setEditedVacation] = useState<IvacationsAdmin | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [first, setFirst] = useState<number>(0);
  const itemPerPage: number = 8;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const vacations = useAppSelector((state) => state.adminVacation.vacationsAdmin);
  const visibleVacations = vacations.slice(first, first + itemPerPage);


  const handleCancel = () => {
    setIsEditMode(false);
  };
  const handlePageChange = (e: any) => {
    setFirst(e.first);

  }



  const handleEdit = (vacation: IvacationsAdmin) => {
    setEditedVacation(vacation);
    setIsEditMode(true);
  };


  const handleUpdate = async (updatedVacation: IvacationsAdmin, vacation_Id: any) => {

    await dispatch(
      updateVacation({ editedVacation: updatedVacation, id: vacation_Id })
    );
    setIsEditMode(false);
    await dispatch(fetchVacations(id));
  };





  const handleDelete = async (vacationId: number) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "This will delete permenantly",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes delete!',
    });

    if (result.isConfirmed) {
      try {
        await dispatch(deleteVacation(vacationId))
        await dispatch(fetchVacations(id));
        Swal.fire('Deleted!', 'Your vacation has been deleted.', 'success');
      } catch (error) {
        Swal.fire('Error', 'An error occurred while deleting the vacation.', 'error');
      }

    }

  };
  const handleLanding = async () => {

    try {
      await dispatch(fetchVacations(id));
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)

      console.log(error, "error caught in fetchVacations");
    } finally {
    }
  }

  useEffect(() => {
    document.title = `Fly-High`
    handleLanding()

  }, [dispatch]);

  const expotData = vacations.map((v) => {
    return {
      destination: v.destination,
      follower: v.followers
    }
  })


  const prepareChartData = () => {
    const labels = vacations.map((vacation) => vacation.destination);
    const data = vacations.map((vacation) => vacation.followers);

    return {
      labels,
      datasets: [
        {
          label: 'Followers',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          data,
        },
      ],
    };
  };

  return (
    <m.div className="vacationsContainerAdmin"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.55, ease: "easeOut" }} >
      <div >
        <Header title="Admin Vacation" />
        <div className="userWelcome">
          Welcome, <span className="userName">System Admin</span>!
        </div>
        <Paginator
          first={first}
          rows={itemPerPage}
          totalRecords={vacations.length}
          onPageChange={handlePageChange}
        />
        <Button className='AddVacation' label='Add Vacation' onClick={() => navigate("/addVacations")} />
        <br />

        <Button className='toggleChart' onClick={() => setShowChart(!showChart)}>Toggle Chart</Button>
        <br />

        {showChart &&
          (
            <div>

              <div className="csvDownloadContainer">
                <CSVLink data={expotData} className="csvDownloadLink">
                  Download CSV File
                </CSVLink>
              </div>
              <Chart
                type="bar"
                className='myChart'
                data={prepareChartData()}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { stacked: true },
                    y: { stacked: true },
                  },
                }}
              />
            </div>
          )

        }
        <br />

        <div>
          {isLoading ? (
            <CircularColor />
          ) : (
            <div key={"vacationsDiv"}>
              {visibleVacations.map((vacation, i) => (
                <m.div
                
                key={i}
                  transition={{ duration: 0.75, ease: "easeIn", delay: 0.3 }}
                >
                  <Card title={<div className="cardTitle">{vacation.destination}</div>} className="cardContainerAdmin" key={vacation.vacation_Id}>
                    <div className='buttonsAdmin'>
                      <Button onClick={() => handleEdit(vacation as any)}>Edit</Button>
                      <Button onClick={() => handleDelete(vacation.vacation_Id)}>Delete</Button>
                    </div>

                    <img
                      src={vacation.image}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = alt;
                      }}
                      className="cimagesAdmin"
                      alt={vacation.destination}
                    />

                    <div className="cardDetailsAdmin">
                      <p>{vacation.description}</p>
                    </div>
                    <p className='priceAdmin'> {vacation.price} $</p>
                    <div className="datesAdmin">
                      <p className="dateText">
                        {vacation.start.split(' ')[0].split('-').reverse().join('/')} -{' '}
                        {vacation.end.split(' ')[0].split('-').reverse().join('/')}
                      </p>
                    </div>
                  </Card>
                </m.div>
              ))}
              {isEditMode && (
                <div className="editVacationOverlay">
                  <div className="editVacationContainer">
                    <EditForm
                      vacation={editedVacation}
                      onEditComplete={() => setIsEditMode(false)}
                      updateFunction={handleUpdate}
                      onCancel={handleCancel}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>





      </div>
    </m.div>
  );
};

export default AdminVacationComponent;
