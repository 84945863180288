import React, { useEffect, useRef, useState } from 'react';
import { userData } from "../../handlers/hashData";
import { Rating } from 'primereact/rating';
import { set } from "date-fns";
import Swal from "sweetalert2";
import showToast from "../../handlers/toast";
import { Toast } from "primereact/toast";
import "./AddReview.css"; 
const { id, role, first_name,last_name } = userData;
const userRole = role;



const AddNewReview = () => {
  const [newReviewRating, setNewReviewRating] = useState(0);
  const [newReviewText, setNewReviewText] = useState("");
  const toastRef = useRef<Toast>(null);



  const addReview = () => {
    setNewReviewRating(0);
    setNewReviewText("");
    if (! newReviewRating ||!newReviewText  ) {
      return showToast(toastRef, 'error', 'Error', 'Please fill in all fields.');
    }

    setTimeout(()=>{ Swal.fire({
      title: "Thank You - Review Registered",
      text: "Thank you for your honest opinion - We will consider pinning down your comment in our homepage",
      icon: "success",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    }).then(() => {
      return
    });},1500)
   
  };
  return (
    <div className="formDiv">
      <Toast ref={toastRef} />

      <h2>Add a Review</h2>
    <div className="reviewForm">
      
    <div className="ratingGroup">
      <Rating
      className="rating"
        value={newReviewRating}
        onChange={(e:any) => setNewReviewRating(e.value)}
        cancel={false}
      />
      <textarea
      className="txtArea"
        placeholder="Write your review..."
        value={newReviewText}
        onChange={(e:any) => setNewReviewText(e.target.value)}
      />
      <button className="myButton" onClick={addReview}>Submit</button>
    </div>
  </div>
  </div>
  );
};

export default AddNewReview;
