import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";

interface LoginState {
  email: string
  password: string
}
 const myDevUrl = "https://vacations-project-api-dv5a.onrender.com"
 export const myUrl = "http://localhost:4101"


export const loginAsync = createAsyncThunk(
  "login/loginAsync",
  async (payload: LoginState) => {
    try {
      const response = await axios.post(`${myUrl}/auth/login`, payload, {
        headers: {
        }
      });


      return response.data;
    } catch (error) {

      throw new Error("login Failed")
    }
  }
);
export const updatePasswordAsync = createAsyncThunk(
  "update/updatePasswordAsync",
  async (payload: { id: number; password: string }, { rejectWithValue }) => {
    try {

      const response = await axios.put(
        `${myUrl}/auth/passwordReset/${payload.id}`,
        { password: payload.password }

      );

      return response.data;
    } catch (error) {
      console.error("Password update failed:", error);
      return rejectWithValue("Failed to update password ");
    }
  }
);
export const getIdAuthAsync = createAsyncThunk(
  "get/getIdAuth",
  async (payload: { question: string, email: string }, { rejectWithValue }) => {
    try {
      const { question, email } = payload
      const response = await axios.get(
        `${myUrl}/auth/salvageId?question=${question}&email=${email}`
      );


      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to update password ");
    }
  }
);


interface ILogin {
  user: any | LoginState
  loading: boolean
  error: undefined | string
  status: "idle" | "loading" | "failed" | "fulfilled"
}

const initState: ILogin = {
  user: undefined,
  loading: false,
  error: undefined,
  status: "idle",
}

const loginSlice = createSlice({
  name: "login",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true
        state.error = undefined
        state.status = "loading"
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading = false
        state.user = action.payload
        state.status = "fulfilled"
      })

      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false
        state.error = (action.error as Error | undefined)?.message
        state.status = "failed"
      })
      .addCase(updatePasswordAsync.pending, (state) => {
        // Handle pending state if needed
      })
      .addCase(updatePasswordAsync.fulfilled, (state, action) => {
        console.log("success");

      })
      .addCase(updatePasswordAsync.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
      });
  },
})

export default loginSlice.reducer