import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logoImage from '../../../upload/logo.png';
import './HomePage.css';
import { classNames } from 'primereact/utils';
import { userData } from '../../handlers/hashData';
import { Rating } from 'primereact/rating';
import nyc from '../../../upload/nyc.jpg';
import kyoto from '../../../upload/kyoto.jpg';
import santorini from '../../../upload/santo.jpg';
import alt from '../../../upload/alt.jpg';
import newUserGuideVideo from "../../../upload/newHere.mp4"
import AddNewReview from '../../UI-Components/addReviews';
import ChatAvatar from '../../UI-Components/chatAvatar';
import Chatbot from '../../UI-Components/chatbot';
import { motion as m, useScroll } from "framer-motion"

const initialReviews = [
  {
    author: 'Nikol Malul',
    rating: 5,
    text: 'I had an amazing experience with Fly-High! The trip was well-organized, and I got to explore beautiful destinations. Highly recommended!',
    image: "https://media.istockphoto.com/id/468382096/photo/scottish-fold-shorthair-cat-resting-on-chair.jpg?s=612x612&w=0&k=20&c=h4wd1YM47fJgJwi9B-HnFxaFYE1M1WUB-d7atkH7mG8=",

  },
  {
    author: 'Yuval Chen',
    rating: 4,
    text: 'Fly-High exceeded my expectations! The accommodations were top-notch, and the activities were so much fun. I\'ll definitely book again.',
    image: "https://media.istockphoto.com/id/1476170969/photo/portrait-of-young-man-ready-for-job-business-concept.jpg?s=612x612&w=0&k=20&c=w8SlKv-4u6xYyU07CXeBRvfW6F0iYx-a7HR2ChM8ZbU="


  },
  {
    author: 'Max Brenner',
    rating: 5,
    text: 'I\'m a frequent traveler, and Fly-High is one of the best travel companies I\'ve come across. Their customer service is outstanding!',
    image: "https://media.istockphoto.com/id/537406958/photo/max-brenner.jpg?s=612x612&w=0&k=20&c=aqutOseyz64rojpc2407FYFI41_k2rJ9oFQ6NuK-iWw=",
  }

];

const { id, role, first_name, last_name } = userData;
const userRole = role;
type IReview = typeof initialReviews
const HomePage = () => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState(initialReviews);
  const [userId, setUserId] = useState<number>(0);
  const [isSupportOn, setIsSupportOn] = useState<boolean>(false)
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const { scrollYProgress } = useScroll();
  const headerRef = useRef(null);
  const trainingGuideRef = useRef(null);
  const whyChooseUsRef = useRef(null);
  const featuredDestinationsRef = useRef(null);
  const customerReviewsRef = useRef(null);
  const footerRef = useRef(null);

  const sectionRefs: any = [
    headerRef,
    trainingGuideRef,
    whyChooseUsRef,
    featuredDestinationsRef,
    customerReviewsRef,
    footerRef,
  ];
  // const headerInView = useInView(headerRef,{ once: true });
  // const trainingGuideInView = useInView(trainingGuideRef,{ once: true });
  // const whyChooseUsInView = useInView(whyChooseUsRef,{ once: true });
  // const featuredDestinationsInView = useInView(featuredDestinationsRef,{ once: true });
  // const customerReviewsInView = useInView(customerReviewsRef,{ once: true });



  useEffect(() => {
    document.title = `Home`
    setUserId(Number(id));
  }, []);

  const scrollToNextSection = () => {
    if (currentSectionIndex < sectionRefs.length - 1) {
      const nextSectionIndex = currentSectionIndex + 1;
      sectionRefs[nextSectionIndex].current.scrollIntoView({ behavior: 'smooth' });
      setCurrentSectionIndex(nextSectionIndex);
    }

  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setCurrentSectionIndex(0);
  };

  const handleSupportChat = async () => {
    setIsSupportOn(!isSupportOn)
  };

  return (
    <div className="mainHomePageDiv">
    <div className="homepage">
      <div className="scrollDown" onClick={() => scrollToNextSection()}>
        <i className="pi pi-angle-down"></i>
      </div>
      <div className="scrollUp" onClick={() => { scrollToSection() }}>
        <i className="pi pi-angle-double-up"></i>
      </div>
      <div className='chatFeature'>
      <ChatAvatar onClick={handleSupportChat} />
      </div>
      {isSupportOn && (
        <Chatbot closeChat={() => { setIsSupportOn(false) }} />)}

      <m.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.75, ease: "easeOut" }}
      >
        <header className="header" ref={headerRef}>


          <img src={logoImage} alt="Fly-High Logo" className="logo" />
          <h1 className="title">Welcome to Fly-High</h1>
        </header>
        <section className="content">
          <p className="description">
            At Fly-High, we specialize in creating unforgettable vacation experiences. Whether you're seeking adventure, relaxation, or cultural exploration, we've got you covered. Join us and embark on the journey of a lifetime!
          </p>
        </section>
        <div className="cta-buttons">
          {!userId ? (
            <div className="buttons">
              <Link to="/login" className="login-button">
                <span className={classNames('pi', 'pi-sign-in')}></span> Login
              </Link>
              <Link to="/sign-up" className="signup-button">
                <span className={classNames('pi', 'pi-user-plus')}></span> Sign Up
              </Link>
            </div>
          ) : (
            <>
              {userRole === 'user' && (
                <div className="buttons">
                  <Link to="/vacations" className="login-button">
                    <span className={classNames('pi', 'pi-search')}></span> Explore Vacations
                  </Link>
                </div>
              )}
              {userRole === 'admin' && (
                <div className="buttons">
                  <Link to="/adminVacation" className="login-button">
                    <span className={classNames('pi', 'pi-cog')}></span> Admin Vacations
                  </Link>
                </div>
              )}
            </>
          )}
        </div><br />
        <section className="features" >
          <div className="feature">
            <i className="pi pi-globe myIcon" />
            <h2>Explore the World</h2>
            <p> Discover new destinations and cultures with our guided tours.</p>
          </div>
          <div className="feature">
            <i className="pi pi-sun myIcon" />
            <h2>Relax and Unwind</h2>
            <p>Escape the daily grind and unwind in the world's most beautiful resorts.</p>
          </div>
          <div className="feature">
            <i className="pi pi-calendar myIcon" />
            <h2>Adventure Awaits</h2>
            <p>Seek thrill and adventure with our exciting outdoor activities.</p>
          </div>
        </section>
      </m.div>


      <section

        className="trainingGuide" ref={trainingGuideRef}>
        <h2>   New User Video Guide   </h2>
        <br />
        <m.div
          initial={{ y: 100, opacity: 0 }}
          transition={{ duration: 0.75, ease: "easeIn", delay: 0.3 }}
          whileInView={{ y: 0, opacity: 1 }}
        >
          <video className="videoContainer" controls width="840" height="460">
            <source src={newUserGuideVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </m.div>
      </section>
      <div className='page'>
        <m.section
            initial={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.75, ease: "easeIn", delay: 0.3 }}
            whileInView={{ y: 0, opacity: 1 }}
          className="whyChooseUs" ref={whyChooseUsRef}>
          <h2>Why Fly-High for Your Dream Getaways?</h2>
          <p>
            Fly-High is your go-to destination for unforgettable travel experiences. Our simple one-page application brings the world of flights and vacations to your fingertips. Discover why Fly-High is the perfect choice for your next adventure:
          </p>
          <ul>
            <li><span className="bullet">✔</span> Seamless Flight Booking: Our user-friendly platform makes booking flights a breeze, ensuring you're always ready for your dream getaway.</li>
            <li><span className="bullet">✔</span> Vacations: Explore a collection of vacations, each with a detailed description. Follow or unfollow to build your dream.</li>
            <li><span className="bullet">✔</span> Your Personal Travel Hub: Keep track of your followed vacations and filter them with ease. Choose between followed vacations and upcoming trips.</li>
            <li><span className="bullet">✔</span> Tailored Recommendations: Our platform provides personalized vacation suggestions based on your preferences, making planning a breeze.</li>
          </ul>
          <br />
          <p>
            Fly-High simplifies your travel experience, allowing you to focus on what truly matters – creating lasting memories. Get started on your journey with Fly-High today.
          </p>
        </m.section>
      </div>


      <div className='page'>

        <section className="featuredDestinations" ref={featuredDestinationsRef}>
          <h2>Featured Destinations</h2>
          <p>Explore some of our top travel destinations and start planning your next adventure.</p>
          <m.div
            initial={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.75, ease: "easeIn", delay: 0.3 }}
            whileInView={{ y: 0, opacity: 1 }}
            className="destinationCards">
            <div className="destinationCard">
              <img src={nyc} alt="New York City" />
              <h3>New York City</h3>
              <p>Experience the vibrant energy of the Big Apple with its iconic landmarks and diverse culture.</p>
            </div>
            <div className="destinationCard">
              <img src={kyoto} alt="Kyoto, Japan" />
              <h3>Kyoto, Japan</h3>
              <p>Discover the rich history and serene beauty of Kyoto, home to ancient temples and traditional tea houses.</p>
            </div>
            <div className="destinationCard">
              <img src={santorini} alt="Santorini, Greece" />
              <h3>Santorini, Greece</h3>
              <p>Relax on the beautiful beaches of Santorini and enjoy breathtaking sunsets over the Aegean Sea.</p>
            </div>
          </m.div>
        </section>
      </div>
      <section className="customerReviews" ref={customerReviewsRef}>
        <h2>Customer Reviews</h2>
        <div className="reviewCards">
          {reviews.map((review) => (
            <div className="reviewCard" key={review.author}>
              <img src={review.image || alt} alt={`Customer ${review.author}`} className="reviewAvatar" />
              <m.div
                initial={{ x: 100, opacity: 0 }}
                transition={{ duration: 0.75, ease: "easeIn", delay: 0.3 }}
                whileInView={{ x: 0, opacity: 1 }}
                className="reviewContent">
                <br />
                <Rating value={review.rating} cancel={false} />
                <br />
                <p className="reviewText">{review.text}</p>
                <br />
                <p className="reviewAuthor">- {review.author}</p>
                <br />
              </m.div>
            </div>
          ))}
          <AddNewReview />
        </div>
        <br /><br />
      </section>
      <footer className="footer" ref={footerRef}>
        <div className="footer-content">
          <p>&copy; {new Date().getFullYear()} Fly-High</p>
          <div className="contact-support">
            <p>Contact Our Support Team:</p>
            <a href="mailto:support@flyhigh.com">support@flyhigh.com</a>
          </div>
        </div>
      </footer>
    </div>
    </div>
  );
};

export default HomePage;
