import { useEffect, useRef, useState } from "react";
import { addVacation } from "../Vacations/vacationSlice";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./addVacations.css";
import { useAppDispatch } from "../../../app/hooks";
import { Toast } from "primereact/toast";
import showToast from "../../handlers/toast";
import { useNavigate } from "react-router-dom";
import Header from "../../UI-Components/header";
import CircularColor from "../../UI-Components/Spinner/Spinner";


export default function AddVacationAdmin() {
  const toastRef = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const [destination, setDestination] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [start, setStart] = useState<string>("");
  const [end, setEnd] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [image, setImage] = useState<string>("");
  const [date, setDate] = useState<string>("");


  useEffect(()=>{document.title = `Add Vacation`},[])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate()
  const handleSubmit = async (e: any) => {
    setIsLoading(true);
    if (
      !destination ||!description ||!start ||!end ||price < 0 || price > 10000 ||
      new Date(end) < new Date(start)
    ) {
      setIsLoading(false);
      return showToast(
        toastRef,
        "error",
        "Error",
        "Please fill in all fields correctly."
      );
    }

    const newVacation = {
      destination,
      description,
      start,
      end,
      price,
      image,
    };


    if (!newVacation.destination || !newVacation.description || !newVacation.start || !newVacation.end || !newVacation.price || !newVacation.image) {
      showToast(toastRef, 'error', 'Error', 'Please fill in all fields.');
      setIsLoading(false);

      return;
    }
    try {
      // await dispatch(addVacation(newVacation));
      const result = await dispatch(addVacation(newVacation));
      
      setIsLoading(false);
      console.log(result);
      if (addVacation.fulfilled.match(result)) {
        showToast(toastRef, 'success', 'Vacation Created', 'Vacation has been successfully created.');
        setDestination("");
        setDescription("");
        setStart("");
        setEnd("");

        setPrice(0);
        setImage("");
         navigate("/adminVacation")
      } 
      else {
        showToast(toastRef, 'error', 'Error', 'Failed to add vacation.');


      }

    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
      if (error.response?.status === 400) {
        showToast(toastRef, "error", "Error", "Vacation dates overlap with existing vacation.");
        setIsLoading(false);
      } 
      else {
        showToast(toastRef, 'error', 'Error', 'An error occurred while creating the vacation.');
        // console.error("Error adding vacation:", error);
      }

    }
  };
  const handleReturn = () => {
    navigate("/adminVacation")
  }
  return (
    <div className="addVacation">
      <Toast ref={toastRef} />
      <div className="headerDiv">
        <Header title="Add Vacation" />
      </div>
      <Button className="returnButton" onClick={handleReturn}>X</Button>
      <Card className="addVacationCard">
        <InputText
          className="inputText"
          placeholder="Destination"
          value={destination}
          onChange={(e) => setDestination(e.target.value)}
          required
        />
        <textarea
          className="addVacationTextarea"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <InputText
          className="inputText"
          placeholder="Start Date"
          value={start}
          type="date"
          onChange={(e) => {setStart(e.target.value),setDate(e.target.value)}}
          required
        />
        <br />
        <InputText
          className="inputText"
          placeholder="End Date"
          type="date"
          min={date}
          value={end}
          onChange={(e) => setEnd(e.target.value)}
          required
        />
        <InputText
          className="inputNumber"
          placeholder="Fill from 0 - 9999"
          type="number"
          value={price as any}
          onChange={(e: any) => setPrice(Number(e.target.value) as number)}
          required
        />
        <InputText
          className="inputText"
          placeholder="Image URL"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          required
        />

        <Button className="submitButtonAddVacation" onClick={handleSubmit} label="Add Vacation" />
        {isLoading && <CircularColor />}


      </Card>

    </div>
  );
}
