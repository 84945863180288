import { useRef, useState } from "react";
import { loginAsync } from "./loginSlice";
import {  useNavigate } from "react-router-dom";
import { object, string } from "zod";
import { useAppDispatch } from "../../../app/hooks";
import { Toast } from "primereact/toast";
import showToast from "../../handlers/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./loginStyle.css";
import { encryptData, userData } from "../../handlers/hashData";
import ChatAvatar from "../../UI-Components/chatAvatar";
import Chatbot from "../../UI-Components/chatbot";


const LoginComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toastRef = useRef<Toast>(null);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const loginSchema = object({
    email: string().email("Invalid email"),
    password: string().min(6, "Password must be at least 6 characters"),
  });
    const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isSupportOn, setIsSupportOn] = useState<boolean>(false)


  const handleAdmin = async () => {
     window.location.href="/home"

  };
  const handleUser = async () => {
    window.location.href="/home"
  };

  const handleNotIn = () => {
    navigate("/sign-up")
  };
  const handleForgot = () => {
    navigate("/reset")
  };
  const isValidEmail = (email:string) => {
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return emailRegex.test(email);

  };
  const isEmailValid = isValidEmail(email);
  const validateForm = () => {
    let isValid = true;
    setEmailError(null);
    setPasswordError(null);

    if (!email || !isEmailValid){
      setEmailError("Invalid email format");
      isValid = false;
    }

    if (!password || password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      isValid = false;
    }
    return isValid;
  };
  const handleLogin = async () => {
    try {
        if (!validateForm()) {
          return;
        }
      const response = await dispatch(loginAsync({ email, password }));
  
      if (loginAsync.fulfilled.match(response)) {
        const token = response?.payload?.token
        // const role = response?.payload?.user?.role
      const userRecord = response?.payload?.user
        const exp = response?.payload?.expiration
        
        const encryptedData = encryptData(userRecord)
        
        localStorage.setItem("token", token)
        localStorage.setItem("exp", exp)
        localStorage.setItem("hashedData", encryptedData)
        if (userData.role === "admin"){
          handleAdmin()
        } else{
      handleUser()
            
        }
      } else {
        showToast(toastRef, 'error', 'Error', 'The email or password do not exist.');
      }
    } catch (error) {
      console.log(error,"Error");
      
    }
  };


  const handleSupportChat = async () => {
    setIsSupportOn(!isSupportOn)
  };

  return (<div className="theWhole">
       <ChatAvatar onClick={handleSupportChat} />
    <div className="loginStyle">
      {isSupportOn && (
        <Chatbot closeChat={() => {setIsSupportOn(false) }} />
        )
      }
      
      <Toast ref={toastRef} />
        <section>
      <div className="mainDiv">
        Email
        <InputText
          className="inputText"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError && <div className="error">{emailError}</div>}
        Password
        <InputText
          className="inputText"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e)=>{e.code === "Enter"&&handleLogin() }}
        />
        {passwordError && <div className="error">{passwordError}</div>}
      </div>
      <Button  className="submitButtonLogin" type="button" onClick={handleLogin}>
        Login
      </Button>
      <div className="signInQ">
        <span> Did not sign up yet ?</span>
        <button className="registerBtn" onClick={handleNotIn}>
          Register!
        </button>
        <br />
        <span> Forgot your password ?</span>
        <button className="registerBtn" onClick={handleForgot}>
          Click Here!
        </button>
      </div>
        <br />
    </section>
  </div>  
  </div>
  );
};

export default LoginComponent;