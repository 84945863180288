import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { Toast } from "primereact/toast";
import showToast from "../../handlers/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ChatAvatar from "../../UI-Components/chatAvatar";
import Chatbot from "../../UI-Components/chatbot";
import { getIdAuthAsync, updatePasswordAsync } from "../login/loginSlice";
import Header from "../../UI-Components/header";


const ResetPasswordComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toastRef = useRef<Toast>(null);
    const [password, setPassword] = useState<string>("")
    const [id, setId] = useState<number>(0)
    const [matchPassword, setMatchPassword] = useState<string>("")
    const [question, setQuestion] = useState<string>("")
    const [email, setEmail] = useState<string>("")

    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [matchPasswordError, setMatchPasswordError] = useState<string | null>(null)

    const [isSupportOn, setIsSupportOn] = useState<boolean>(false)
    const [isSecondStage, setIsSecondStage] = useState<boolean>(false)
   useEffect(()=>{document.title = `Reset Password`},[])
    const handleUpdate = async () => {
        if (matchPassword !== password) {
            setMatchPasswordError("Passwords must match")
            showToast(toastRef, 'error', 'Error', 'The passwords must match.');
            return 
        }
        try {
            const resultData = await dispatch(updatePasswordAsync({ password: password, id: id }));

            // setIsLoading(false);
            showToast(
                toastRef,
                "success",
                "Password Updated",
                "Password has been successfully updated."
            );
            setTimeout(()=>{navigate("/login")},1500) 
        } catch (error) {
            showToast(toastRef, 'error', 'Error', 'The password has failed to update.');

        }
    };

    const handleSupportChat = async () => {
        setIsSupportOn(!isSupportOn)
    };
    const handleAuth = async () => {
       const payLoad = {email,question}
       
       try { 
            const response = await dispatch(getIdAuthAsync(payLoad))
            setId(response.payload.result[0].id)
            showToast(
                toastRef,
                "success",
                "User identified",
                "Redirected to password reset."
            );
            
            setIsSecondStage(true)

        } catch (error) {
            showToast(toastRef, 'error', 'Error', 'Wrong data given.');
            
        }
    };

    return (
        <div className="loginStyle">
            <ChatAvatar onClick={handleSupportChat} />
            {isSupportOn && (
                <Chatbot closeChat={() => { setIsSupportOn(false) }} />
            )
        }
        <Toast ref={toastRef} />

{  isSecondStage? ( 
            <form>

                 <div style={{position:"relative", right: "3rem"}}>
                <Header title = "Reset Password"/>
                </div>
                <div className="maindiv">
                    New Password
                    <InputText
                        className="inputText"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordError && <div className="error">{passwordError}</div>}
                    Verify New Password
                    <InputText
                        className="inputText"
                        type="password"
                        value={matchPassword}
                        onChange={(e) => setMatchPassword(e.target.value)}
                    />
                    {matchPasswordError && <div className="error">{matchPasswordError}</div>}
                </div>
                <br />
                <div style={{position:"relative", right: "3rem"}}>
                <Button className="submitButtonLogin" type="button" onClick={()=>{ handleUpdate()}}>
                    Update Password
                </Button>
                </div>
            </form>
                ):( <form>
                      <div style={{position:"relative", right: "3rem"}}>
                <Header title = "Authentication Stage"/>
                </div>

                    <div className="maindiv">
                        Email
                        <InputText
                            className="inputText"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {passwordError && <div className="error">{passwordError}</div>}
                        Security Question  - <span><small> Enter the answer from registration </small></span>
                        <InputText
                            className="inputText"
                            type="text"
                            value={question}
                            onChange={(e) => {
                                const q = e.target.value
                                  setQuestion(q)}}
                        />
                        {matchPasswordError && <div className="error">{matchPasswordError}</div>}
                    </div>
                    <br />
                    <div style={{position:"relative", right: "1rem"}}>
                    <Button className="submitButtonLogin" type="button" onClick={()=>{handleAuth()}}>
                        Submit
                    </Button>
                </div>
                </form>)
}


        </div>
    );
};

export default ResetPasswordComponent;